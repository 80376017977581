footer{
	// position: fixed;
 //    bottom: 0;
    width: 100%;
    // z-index: -1; 
    // display: none;
    // height: 355px;
    background: $footer-ligtnes; 
	.social{
		// background: $footer-ligtnes; 
		@include centered;
		text-align: center;
		height: 110px;
	}
	.contacts{
		background: $footer-darknes;
		padding: 35px 0 30px;
		p, a:not(.getInTouch):not(.pdf){
			color:#fff;
			display: block;
			@include opensanslight;
			font-size: $font-size-small; 
			letter-spacing: 0.5px;
			line-height: 24px;
			text-align: center;
		} 
		.getInTouch, .pdf{
			@extend .btn;
			width:100%;
			margin: auto;
		}
		.getInTouch{
			background: $green;
			margin-bottom: 20px;
			color:#fff;
			&:hover{
				background: lighten($green, 10%); 
			}
		}
		.pdf{
			border: 1px solid #fff;
			color:#fff;
			&:hover{
				background: #fff;
				color:$dark-text;
			}
		}
	}
	.lionline{
		// background: $footer-ligtnes; 
		p{
			@include opensanslight;
			text-align: center;
			color: #fff;
			line-height: 40px;
			font-size: $font-size-small;
			letter-spacing: 0.5px;
		}
	}
	@media only screen and (min-width: 64.063em) {
		.contacts{
			.getInTouch, .pdf{
				width:350px; 
			}
		}
	}
	@media only screen and (min-width: 40.063em) {
		position: fixed;
		display: none;
   		bottom: 0;
   		// height: 355px;
   		&:before {
		    content: '';
		    height: 300px;
		    display: block;
		}
	}
	@media only screen and (max-width: 40em) {
		.social{
			flex-direction: column;
			height: auto;
			a{
				margin:8px;
			}
		}
		.contacts .getInTouch{
			margin-top: 20px; 
		}
	} 
} 