

 p{
    color: $dark-text;
 }

.btn{
    line-height: 55px;
    text-transform: uppercase;
    text-align:center; 
    display: block;
    font-size: $font-size-large;
    letter-spacing:$letter-spacing-large;
    transition:0.3s;
    // @include poiretone;
    @extend .poiretonelight;  
    &:focus{
        color: inherit;
    }
    @media only screen and (max-width: 40em) {
        width:calc(100% - 30px);
    } 
}

.section-title{
    h2{
        // @include poiretone;
        @extend .poiretone;
        font-size: $font-size-large;
        color:$dark-text;
        letter-spacing: $letter-spacing-large;
        line-height: normal;
        padding-bottom: 5px;
        border-bottom: 1px solid $dark-text;
        margin:60px 0 20px;
        text-transform: uppercase;
    }
    p{
        font-size: $font-size-small; 
        @include opensanslight;
        letter-spacing: $letter-spacing-small;
    }
}

.slick-slide {
    outline: none;
} 
.slick-slider {
    margin-bottom: 0px;
}

.container{ 
    background: #fff;
    display:inline-block;
    width:100%;
    position: relative;
    z-index: 10;
    @media only screen and (min-width: 40.063em) { 
        // margin-bottom: 355px;
        margin-bottom: 245px;
    } 
}
/*------------------------------------*\
        index.html
\*------------------------------------*/


#intro{
    background: url('../images/intro.jpg') 50%;
    background-size: cover; 
    height: 100vh;
    width: 100%;
    .intro-header{
        padding-top: 12px;
        max-width: 1400px;
        z-index: 10;
        position: relative;
        p{
            letter-spacing:0.5px; 
            float: right;
        }
        p, 
        ul li a{
            // @include poiretone;
            @extend .poiretone;
            font-size: $font-size-large;
            color: #fff;
            line-height: 50px;
        }
        ul{
            list-style: none;
            font-size: 1em;
            float: right;
            margin: 0;
            // display:none;
            li{
                text-transform: uppercase;
            }
            .active{
                display: none;
            }
        }
    }
    .wrap{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        h1{ 
            @extend .poiretonelight;
            color: #fff; 
            text-align:center;
            letter-spacing: 0.5px;
            // line-height: normal;
            line-height: 1.25em;
            text-transform: uppercase;
            @media only screen and (max-width: 64em) {
                br{
                    display:none; 
                }
            }
        }
        .arrow{
            margin: 70px auto 0;
            display: none;
            cursor: pointer;
            @media only screen and (min-width: 64.063em) {
                display: block;
            } 
        }
    }
    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%; 
        height: 100%;
        background: url('../images/intro-blur.jpg') 50%;
        display: block;
        background-size: cover;
        z-index:-33;
        opacity: 0;
    }
    @media only screen and (min-width: 40.063em) {
        position: fixed;
        h1{
            font-size: 4.5em;
            // margin-top: 100px;
        }
        .intro-header{
            ul{
                padding-left: 115px;
            }
        }
    }
    @media only screen and (min-width: 64.063em) {
        h1{
            font-size: 7.2em;
        }
    }
    @media only screen and (max-width: 40em) {
        height: calc( 100vh - 80px );
        .intro-header{
            img{
                display:none; 
            }
        }
    } 
} 
.intro-lining{
    height: calc(100vh - 60px);
    @media only screen and (max-width: 640px) {
        display: none;
    }
}

#servies{
    background: $servies-bg;
    padding-bottom: 40px;
    .servies-wrap{
        display: flex;
        justify-content: space-between;
    }
    .service{
        background: #eff1f3;
        margin: 75px 0px;
        height: 345px;
        width: 290px;
        text-align:center;
        padding: 15px;
        position:relative;
        overflow: hidden;
        // border-bottom: 15px solid #3ecc60;
        &:before {
            content: "";
            display: block;
            width: 100%;
            top: 0;
            left:0;
            height: 100%;
            // opacity: 0;
            transition: 0.2s;
            background-size: cover;
            position: absolute;
        }
        // &:hover:before {
        //     transition: 0.4s;
        //     opacity: 1;
        // }
        

        // .bg-img{
        //     border-bottom: 15px solid #3ecc60;
        //     position: absolute;
        //     left: 0;
        //     top:0;
        //     transform: translate(0, -100%);
        //     transition: 0.5s;
        // }
        span{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 15;
        }
        svg{
            margin: 65px auto 30px;
        }
        svg, h3, p{
            position:relative;
            z-index: 2;
            transition: 1s;
        }
        h3{
            // @include poiretone;
            @extend .poiretone;
            text-transform: uppercase;
            font-size: $font-size-large;
            letter-spacing: $letter-spacing-large;
        }
        p{
            opacity: 0;
            color: #fff;
            @include opensanslight;
            font-size: $font-size-small;
            line-height: normal;
        }
    }
    .development:before{
        background-image: url("../images/web_development.png");
    }
    .design:before{
        background-image: url("../images/web_design.png");
    }
    .support:before{
        background-image: url("../images/sites_support.png");
    }
    .btn{
        border: 1px solid $green;
        margin: auto;
        width: 290px;
        color: $green; 
        &:hover{
            background: $green;
            color: #fff;
        }
    }
    @media only screen and (max-width: 40em) {
        .servies-wrap{
            flex-direction: column;
            align-items: center;
        }
        .service{
            background: #eff1f3;
            margin: 25px 0px;
        }
    }
    @media only screen and (min-width: 64.063em) {
        .service{
            &:before{
                opacity: 0;
            }
            &:hover:before {
                transition: 0.5s;
                opacity: 1;
            }
            &:hover{
                // border-bottom-width: 0px;
                transition: 0.3s;
                svg, h3, p{
                    transform: translate(0, -30px);
                }
                // .bg-img{
                //     top: 100%;
                // }
                h3{
                    color: #fff;
                }
                p{
                    opacity: 1;
                    transition: 1.5s;
                }
            }
        }
    }
}

#works{
    .portfolio-wrap{
        position: relative; 
        margin: 35px 0 50px;
        overflow: hidden;
        .slick-slider{
            position: static;
        }
        .slick-arrow{
            // background: rgba(255, 255, 255, 0.85);
            &:before{
                display: inline-block;
                height: 70px;
                width: 39px;
                background-repeat: no-repeat;
                content: " ";
            }
        }
        .slick-prev:before{
            background-image: url('../images/arrow_left.svg');
        }
        .slick-next:before{
            background-image: url('../images/arrow_right.svg'); 
        }
    }
    .portfolio{
        margin:  auto; 
        max-width: 1070px;
        .slick-list{
            overflow: visible; 
        }
        // .project{
        //     position:relative;
        //     overflow: hidden;
        //     &:before {
        //         content: "";
        //         position: absolute;
        //         height: 100%;
        //         width: 100%;
        //         background-color: rgba(25, 52, 65, 0.35);
        //         transition: 0.5s;  
        //         z-index: 5;
        //     }
        //     h3, p, span{
        //         position: absolute;
        //         color: #fff;
        //         transition: 0.3s;
        //         z-index: 10;
        //         letter-spacing: $letter-spacing-small;
        //     }
        //     h3{
        //         // @include poiretone;
        //         @extend .poiretone;
        //         left: 30px;
        //         right: 30px;
        //         top: 25px;
        //         text-transform: uppercase;
        //         font-size: $font-size-large;
        //     }
        //     p{
        //        @include opensanslight;
        //         font-size: $font-size-small;
        //         line-height: normal;
        //         left:30px;
        //         right: 30px;
        //         bottom:30px;
        //         // display: none;
        //     }
        //     span{
        //         bottom: 0;
        //         right: 0;
        //         left:0;
        //         background: $green;
        //         line-height: 40px;
        //         font-size:$font-size-large;
        //         padding-left: 30px;
        //         // height: 40px;
        //         // @include poiretone;
        //         @extend .poiretone; 
        //     }
        //     .logo{
        //         position: absolute;
        //         top: 0;
        //         bottom: 0;
        //         left: 0;
        //         right: 0;
        //         margin: auto;
        //         width: auto;
        //     }
        //     img:not(.logo){
        //         width: 100%;
        //     }
        // }
    }
    .show-all, .pdf{
        @extend .btn;
        display: inline-block;
        margin-bottom: 25px; 
    }
    .pdf{
        @extend .btn;
        background: $green;
        color:#fff; 
        &:hover{
            background: lighten($green, 10%);
        }
    } 
    .show-all{
        border: 1px solid $green;
        color: $green;
        &:hover{
            background: $green;
            color:#fff;
        }
    }
    // .add-project{
    //     position: relative;
    //     div{
    //         position: absolute;
    //         height: 150px;
    //         top: 0;
    //         z-index: 999;
    //         bottom: 0;
    //         display: block;
    //         margin: auto;
    //         width: 200px;
    //         left: 0;
    //         right: 0;
    //         text-align: center;
    //         h3{
    //             letter-spacing: $letter-spacing-small;
    //             color: $dark-text;
    //             font-size: 1.8em; 
    //             @extend .poiretonelight;
    //         }
    //         span{
    //             height: 55px;
    //             width: 55px;
    //             border: 2px solid $green;
    //             display: block;
    //             margin: 30px auto 0;
    //             color:$green;
    //             font-size: 55px;
    //             line-height: 40px;
    //             @extend .poiretonelight;
    //             &:hover{
    //                 background: $green;
    //                 transition: 0.3s;
    //                 color: #fff;
    //             }
    //         }
    //     }
    //     &:hover{
    //         img{
    //             opacity: 0;
    //             transition: 0.3s;
    //         }
    //     }
    // }
    @media only screen and (min-width: 40.063em) {
        .show-all, .pdf{
            width: 290px;
            margin: 0 15px 50px; 
        }
    }
    @media only screen and (min-width: 1024px) {
        // .portfolio-wrap{
        //     position: relative; 
        //     margin: 35px 0 50px;
        //     overflow: hidden;
        //     .slick-slider{
        //         position: static;
        //     }
        // }
        .slick-arrow{
            width: calc((100% - 1070px) / 2);
            height: 100%;
            top: 0;
            margin-top: 0;
            z-index:10;
        }
        .slick-next {
            right: 0;
        }
        .slick-prev{
            left: 0;
        }
        // .project{
        //     &:hover{
        //         &:before {
        //             background-color: rgba(25, 52, 65, 0.6);
        //             transition: 0.5s; 
        //         }
        //         h3{
        //             top: 15px;
        //         }
        //         p{
        //             bottom:70px;
        //         }
        //         span{
        //             height: 40px;
        //         }
        //         .logo{
        //             z-index: 10;
        //         }
        //     }
        //     p{
        //         display: block;
        //     }
        //     span{
        //         height: 0px;
        //     }
        // }
    }
}

#works-plan{
    position: relative;
    height:1075px;
    z-index: 0;
    .name-item{
        margin: 70px 0;
        .slick-slide{
            width: auto!important;
        }
        .slick-list {
            text-align: center;
            .slick-track {
                width: auto!important;
                display: inline-block;
            }
        }
        .slick-current{
            .circle{
                background: $green;
                p{
                    color: #fff;
                }
            }
        }
        .circle{
            height:130px;
            width: 130px;
            background: rgba(255, 255, 255, 0.8);
            border-radius: 50%; 
            display: flex;
            align-items: center;
            text-align: center; 
            margin: 0 15px;
            transition: 0.3s; 
            cursor: pointer;
            p{ 
                color: $green;
                text-transform: uppercase;
                // @include poiretone;
                @extend .poiretone;
                font-size: $font-size-small;
                letter-spacing: 0.75px;
            }
        }
    }
    .image-item{
        position:absolute;
        top:0;
        left: 0;
        width: 100%;
        z-index: -1;
        .slick-slide{
             div{    
                height: 1070px;
                width: 100%; 
                background-size: cover;  
            }
        }
    }
    .description-item{
        .slick-current{
            .description{
                background: rgba(255, 255, 255, 0.9);
            }
        }
        .description{
            height:570px;
            background: rgba(255, 255, 255, 0.4);
            border-radius: 10px;
            padding: 65px 130px;
            margin: 0 30px;
            h3{
                @include opensans;
                font-size:$font-size-large;
                letter-spacing:$letter-spacing-large;
                margin-bottom: 15px;
            } 
            p{
                @include opensanslight;
                font-size:$font-size-small;
                letter-spacing:$letter-spacing-small;
                column-count: 2;
                line-height: 30px;
            }
            img{
                margin-bottom: 25px;
            }
        }
    }
}

#why-we{
    padding-bottom: 55px;
    .advantages-items{
        margin: 10px 50px 30px;
        text-align: center;
        .circle{
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background: #ecfaee;
            transition: 0.3s;
            margin:0 auto 25px;
            @include centered;
            &:hover{
                background: $green;
                div{
                    background-position: 0 -63px;
                }
            }   
            div{
                width: 60px;
                height: 63px;
                background-repeat: no-repeat;
            }
            .hosting{
                background-image: url('../images/icon/hosting.png');
            }
            .design{
                background-image: url('../images/icon/design.png');
            }
            .speed{
                background-image: url('../images/icon/speed.png');
            }
            .team{
                background-image: url('../images/icon/team.png');
            }
            .versions{
                background-image: url('../images/icon/versions.png');
            }
            // img{
            //     height:50px;
            // }
        }
        p{
            // @include poiretone;
            @extend .poiretone;
            font-size: $font-size-large;
            letter-spacing: 0.5px;
            line-height: 30px;
        } 
        .slick-prev{
            background-position: 0 0;
            left: -45px;
        }
        .slick-next{
            background-position: -30px 0;
            right: -45px;
        }
        .slick-prev, .slick-next{
            height: 45px;
            width: 30px;
            background-image: url('../images/arrow.png');
            background-repeat: no-repeat; 
            margin-top: -22px
            &:before{
                content:none;
            }
        }
    }
    .advantages-description{
        p{
            @include opensanslight;
            font-size: $font-size-small;
            letter-spacing: 0.5px;
            line-height: 24px;
        }
    }
}

#why-we-2{
    padding-bottom: 55px;
    ul{
        font-size: 1em;
        li{

        }
    }
    img{
        margin: 20px auto;    
        display: block;
    }
    p{
        text-align: center;
        @extend .poiretone;
        font-size: $font-size-large;
        letter-spacing: 0.5px;
        line-height: 25px;
    } 
}

#start{
    background: $green; 
    padding: 35px 0 40px;
    text-align: center;
    // margin-bottom: 355px;
    p{
        color: #fff;
        font-size:$font-size-small;
        @include opensans; 
        line-height: normal;
        letter-spacing: $letter-spacing-small;
    }
    .btn{
        border:  1px solid #fff; 
        // width: 350px; 
        margin: 30px auto 0;
        color:#fff;
        &:hover{
            background: #fff;
            color:  $green;
        }
    }
    @media only screen and (min-width: 40.063em) {
        .btn{
            width: 350px;
        }
    }
} 


@media only screen and (max-width: 40em) {} 
@media only screen and (min-width: 40.063em) {}
@media only screen and (min-width: 64.063em) {}


// #servies .service {
//     background-color: #eff1f3;
//     height: 340px;
//     text-align: center;
//     padding: 20px;
// }
// .service:hover {
//     background-position-y: 0px;
// }

// .service:after {
//     content: "";
//     display: block;
//     height: 10px;
//     background: #4ecc60;
//     width: 100%;
//     /* transform: translate(0px ,100%); */
//     /* position: relative; */
//     position: absolute;
//     /* top: 0; */
//     bottom: 0;
// }
// .service:hover:after {
//     bottom: -10px;
//     transition: 0.3s;
// }


/*------------------------------------*\
        portfolio.html
\*------------------------------------*/
#slogan{
    // background-image: url('../images/portfolio-bg.jpg');
    background-size: cover;
    h1{ 
        // @include poiretone;
        @extend .poiretone;
        font-size: $font-size-large;
        letter-spacing: $letter-spacing-large;
        text-transform: uppercase;
        text-align: center;
        margin: 75px 0 80px;
        &:after {
            content: ' ';
            display: block;
            height: 2px;
            width: 130px;
            background: $green;
            margin: 10px auto 0;
        }
    }
}
#slogan.portfolio-bg{
    background-image: url('../images/portfolio-bg.jpg');
}

#filter{
    ul{
        list-style:none;
        font-size: 1em;
        display: inline-block;
        li{
            float:left;
            a{
                font-size:$font-size-small;
                @include opensans;
                letter-spacing: $letter-spacing-small;
                line-height: 75px;
                padding: 0 35px;
                color:$dark-text;
            }
        }
    }
    @media only screen and (max-width: 1024px) {
        display: none;
    }
}

#portfolio{
    background: $servies-bg;
    min-height: 100vh;
    .grid{
        .element-item{
            // width: 33.33333333%;
            display: block;
            float: left;      
        } 
        // .project{
        //     position: relative;
        //     img:not(.logo){
        //         width:100%;
        //     }
        //     .caption{
        //         position: absolute;
        //         top: 0;
        //         left: 0;
        //         width: 100%;
        //         height: 100%;
        //         padding: 30px;
        //         display: flex;
        //         justify-content: space-between;
        //         align-items: center;
        //         flex-direction: column;
        //     }
        //     a, p{
        //         // position: absolute;
        //         opacity: 0;
        //         transition: 0.4s;
        //         z-index: 5;
        //         color:#fff;
        //     }
        //     p{
        //         // text-align: center;
        //         top: 30px;
        //         left: 20px;
        //         right:20px;
        //     }
        //     .logo{
        //         // position: absolute;
        //         // top: 0;
        //         // bottom: 0;
        //         // left: 0;
        //         // right: 0;
        //         // margin: auto;
        //         // width: auto;
        //         z-index: 5;
        //     }
        //     a{
        //         @extend .btn;
        //         border: 1px solid #fff;
        //         bottom: 30px;
        //         left: 0;
        //         right:0;
        //         width:290px;
        //         // margin: auto;
        //         &:hover{
        //             background: #fff;
        //             color:$dark-text;
        //         }
        //     }
        //     &:before {
        //         content: "";
        //         position: absolute;
        //         height: 100%;
        //         width: 100%;
        //         transition: 0.5s;  
        //         z-index: 5;
        //     }
        //     &:hover{
        //         &:before {
        //             background-color: rgba(25, 52, 65, 0.6);
        //             transition: 0.5s; 
        //         }
        //         a, p{
        //             opacity: 1;
        //         }
        //     }
        // }
    }
    .grid:after {
      content: '';
      display: block; 
      clear: both;
    }
    @media only screen and (max-width: 40em) {
        .element-item{
            width: 100%;  
        }
    } 
    
    @media only screen and (min-width: 40.063em) {
        .element-item{
            width: 50%;  
        } 
    }
    @media only screen and (min-width: 64.063em) { 
        .element-item{
            width: 33.333333%;  
        } 
    }
}

.disabled{
    @media only screen and (max-width: 64.063em) {
        display: none; 
    }
    @media only screen and (min-width: 64.063em) {
        opacity: 0.2;
        transition: 0.4s;
    }
}


#works,
#portfolio{
    .project{
        position:relative;
        overflow: hidden;
        &:before {
            content: "";
            position: absolute;
            height: 100%;
            width: 100%;
            // background-color: rgba(25, 52, 65, 0.35);
            transition: 0.5s;  
            z-index: 5;
        }
        h3, p, span{
            position: absolute;
            color: #fff;
            transition: 0.3s;
            z-index: 10;
            letter-spacing: $letter-spacing-small;
            opacity: 0;
            text-align: center;
        }
        h3{
            // @include poiretone;
            @extend .poiretone;
            left: 30px;
            right: 30px;
            top: 25px;
            text-transform: uppercase;
            font-size: $font-size-large;
        }
        p{
           @include opensanslight;
            font-size: $font-size-small;
            line-height: normal;
            left:30px;
            right: 30px;
            top: 55px;
        }
        span{
            bottom: 20px;
            right: 0;
            left:0;
            // background: $green;
            line-height: 40px;
            font-size:$font-size-large;
            // padding-left: 30px;
            height: 40px; 
            // @include poiretone;
            @extend .poiretone; 

            border: 1px solid #fff;
            text-align: center;
            margin: auto;
            text-transform: uppercase;
            width: 280px;
            &:hover{
                background: #fff;
                color: $dark-text;
            }
        }
        .logo{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: auto;
        }
        img:not(.logo){
            width: 100%;
        }
    }
    @media only screen and (max-width: 1024px) {
        .project{
            p,span{
                display: none;
            } 
        }
    }
    @media only screen and (min-width: 1024px) {
        .project{
            &:hover{
                &:before {
                    background-color: rgba(25, 52, 65, 0.85);
                    transition: 0.3s; 
                }
                img:not(.logo){
                    transition:10s;
                    transform: scale(1.1) rotate(3deg);  
                }
                // h3{
                //     top: 15px;
                // }
                // p{
                //     bottom:70px;
                // }
                // span{
                //     height: 40px;
                // }
                // span, h3{
                //     opacity: 1;
                //     transition: 0.5s;
                // }
                .logo{
                    z-index: 10;
                }
            }
            // p{
            //     display: block;
            // }
            // span{
            //     height: 0px;
            // }
        }
    }
}
.active{
    opacity: 1!important;
    transition: 0.3s; 
}
/*------------------------------------*\
        contacts.html
\*------------------------------------*/
#slogan.contact-bg{ 
    background-image: url("../images/slogan_contact.jpg");
}
#contacts{
    form{
        margin: 30px 0;
        textarea, input{
            margin-bottom: 20px;
            border: none;
            background: #e8eaec;
            height: 55px;
            width: 100%;
            display: block;
            padding-left: 20px;
            color: $dark-text;
            font-size: $font-size-small; 
            box-shadow: none;
            &:focus{
                background: #c5cccf;
            }
        }
        button{
            margin-top: 20px;
            border: 1px solid $green;
            background: #fff;
            height: 55px;
            font-size: $font-size-large;
            letter-spacing:$letter-spacing-large;
            transition:0.3s;
            width: 100%;
            color: $green;
            padding: 0;
            @extend .poiretonelight;
            &:hover{
                background: $green;
                color: #fff;
            }
        }
    }
    .contacts-info{
        // margin: 30px 0 50px;
        // padding-left: 170px;
        p{
            @extend .poiretone;
            font-size: $font-size-large;
            line-height:30px;
            letter-spacing: $letter-spacing-small;
        }
        span{
            font-size: 1.4em;
            @include opensanslight;
            line-height:18px;
            letter-spacing: $letter-spacing-small;
            margin-bottom: 25px;
            display: block;
        }
    }
    @media only screen and (min-width: 64.063em) {
        form{
            margin: 60px 0 90px;
            textarea, input, button{
                width: 450px;
            }
        }
        .contacts-info{
            margin: 60px 0 90px;
            padding-left: 170px;
        }
    }
    @media only screen and (min-width: 40.063em) {
        form, .contacts-info{
            margin: 40px 0 50px;
        }
    }
}
#map{
    h2{
        font-size: $font-size-large;
        @extend .poiretone;
        text-align: center;
        margin-bottom: 35px;
        letter-spacing: $letter-spacing-large;
        text-transform: uppercase;
    }
}
/*------------------------------------*\
        404.html
\*------------------------------------*/
#gif-bg{
    // background-image: url('../images/404-gif/giphy.gif');
    background-position: 50%;
    background-size:cover;
    height: calc(100vh - 60px);
    display:flex;
    align-items: center;
    &:before{
        position: absolute;
        top:0;
        left: 0;
        content: '';
        width: 100%;
        height: 100%;
        display: block;
        background: url('../images/pixel.png');
    }
    .wrap{
        text-align: center;
        max-width: 640px;
        margin: auto;
        h3{
            color: #fff;
            text-transform: uppercase;
            @extend .poiretonelight;
            letter-spacing: $letter-spacing-large;
            font-size: 3em;
        }
        p, span{
            @include opensanslight;
            letter-spacing: $letter-spacing-small;
            color:#fff;
        }
        p{
            font-size: $font-size-large;
            text-transform: uppercase;
            margin-bottom: 20px;
        }
        span{
            font-size:$font-size-small;
            line-height: normal;
        }
        a{
            @extend .btn;
            border: 1px solid $green;
            margin: 35px 15px 0;
            color: $green;
            &:hover{
                background: $green;
                color: $dark-text;
            }
        }
    }
    @media only screen and (min-width: 40.063em) {
        .wrap{
            a{
                width: calc(50% - 30px);
            }
        }
    } 
}

/*------------------------------------*\
       general portfolio
\*------------------------------------*/
.project-container{
    section{
        position:relative;
    }
    @media only screen and (max-width: 64.063em) {
        section{
            img{
                display: block;
                margin: auto;
                padding: 25px 15px;
            }
            .mokup video{
                display: none; 
            }
        }
    }
    @media only screen and (min-width: 64.063em) {
        section>img{
            display: none;
        }
        .mokup{
            position: absolute;
            right: 0;
            margin: auto;
            video{
                position: absolute;
            }
        }
    }   
}

.next-project{
    background-size: cover;
    // background-image: url("../image/sportfest40/next-project.jpg");
    background-repeat: no-repeat;
    a{
        width: 100%;
        // height: 300px;
        padding: 30px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        p,
        a,
        h4{
            letter-spacing: $letter-spacing-large;
            color:#fff;
            text-align: center;
            @include opensans;
        }
        p{
            font-size: $font-size-small;
        }
        img{
            display: block!important;
            margin: auto;
        }
        a{
            // font-size: $font-size-large;
            // display: block;
            padding:0 15px;
            // line-height: 55px;
            // text-transform: uppercase;
            border: 1px solid #fff;
            // transition:0.3s;
            margin: 15px auto;
            width: calc(100% - 30px);
            @extend .btn;
            @extend .poiretonelight;
            &:hover{
                background: #fff;
                color:$dark-text;
            }
        }
        @media only screen and (max-width: 640px) {
            div{
                width: 100%;
            }
        }
        @media only screen and (min-width: 40em) {
            a{
                width:350px;
            }
        }
        @media only screen and (min-width: 800px) {
            a{
                display: inline-block;
                margin: 0 15px;
            }
        }
        @media only screen and (min-width: 64.063em) {
            height: 360px;
        }
    }
}
/*------------------------------------*\
        sportfest40.html
\*------------------------------------*/
// @media only screen and (min-width: 64.063em) {
//     .intro-project{
//         height: 983px;
//         .mokup-mac{
//             z-index: 5;
//             position: absolute;
//             right: 0;
//             left: 0;
//             bottom: -230px;
//             width: 1282px;
//             margin: auto;
//             video{
//                 position: absolute;
//                 top: 52px;
//                 left: 170px;
//                 width: 939px;
//             } 
//         }
//     }
//     .desktop-screens{
//         height: 3025px;
//         img{
//             position: absolute;
//         }
//         .desktop-screen-1{
//             top: 300px;
//             right: -450px;
//             left: 0;
//             margin: auto;
//         }
//         .desktop-screen-2{
//             top:600px;
//             right: 0;
//             left: -450px;
//             margin: auto;
//         }
//         .desktop-screen-3{
//             top:1190px;
//             right: -450px;
//             left: 0;
//             margin: auto;
//         }
//         .desktop-screen-4{
//             top:1905px;
//             right: 0;
//             left: -450px;
//             margin: auto;
//         }
//     }
//     .mockups{
//         height: 2000px;
//         .iphone{
//             height:700px;
//             padding-top: 70px;
//             .phone-screen-left1, .phone-screen-left2, .phone-screen-right1, .phone-screen-right2{
//                 position: absolute; 
//                 display: block;
//             }
//             .phone-screen-left1{
//                 top: 100px;
//                 left: -500px;
//                 right: 0;
//                 margin: auto;     
//             }
//             .phone-screen-left2{
//                 top: 170px;
//                 right: 0;
//                 left: -950px;
//                 margin: auto;
//             }
//             .phone-screen-right1{
//                 top: 170px; 
//                 right: -950px;
//                 left: 0;
//                 margin: auto;
//             }
//             .phone-screen-right2{ 
//                 top: 100px;
//                 right: -500px;
//                 left: 0;
//                 margin: auto;
//             }
//         }
//         .ipad{
//             .ipad-mockup{
//                 position:relative;
//                 margin: auto;
//                 width:1272px;
//                 .gif{
//                     position: absolute;
//                     top: 98px;
//                     left: 23px;
//                     width: 352px;
//                 }
//             }
//         }
//     }
// }

// .intro-project{
//     background: url('../images/sportfest40/header.jpg')no-repeat 50% 0; 
//     img{
//         margin-top: 400px; 
//     }
// }
// .project-content{
//     position:relative;
//     section{ 
//         position: relative; 
//     }
//     .desktop-screens{
//         background: url('../images/sportfest40/section1.jpg')no-repeat 50% 0; 
//         img{
//             margin: auto;
//             display: block;
//         }
//     }
//     .mockups{
//         background: url('../images/sportfest40/section2.jpg')no-repeat 50% 0; 
//         .iphone{
//             padding: 40px;
//             .iphone-mockup{
//                 position:relative;
//                 margin: auto;
//                 width:300px;
//                 z-index: 99;
//                 .gif{
//                     position: absolute;
//                     top: 72px;
//                     left: 18px;
//                     width: 16.5pc;
//                 }
//             }
//             .phone-screen-left1, .phone-screen-left2, .phone-screen-right1, .phone-screen-right2{
//                 display: none;
//             }
//         }
//         .ipad{
//             .ipad-mockup{
//                 position:relative;
//                 margin: auto;
//                 .gif{
//                     position: absolute;
//                     top: 91px;
//                     left: 172px;
//                     width: 960px;
//                 }
//             }
//         }
//     }
// }
/*------------------------------------*\
        sportfest40.html
\*------------------------------------*/
.sportfest40{
    @media only screen and (min-width: 64.063em) {
        .section-1{
            background: url('../images/sportfest40/section1.jpg')no-repeat 50% 0;
            height: 1029px;
            .mokup-macbook{
                width: 1282px;
                bottom: -200px;
                left: 0px;
                z-index: 9;
                video{
                    width: 939px;
                    left: 171px;
                    top: 52px;
                }
            }
        }
        .section-2{
            background: url('../images/sportfest40/section2.jpg')no-repeat 50% 0;
            height: 3025px;
            img{
                position: absolute;
                margin: auto;
                display: block;
            }
            .desktop-screen-1{
                top: 300px;
                right: -450px;
                left: 0;
            }
            .desktop-screen-2{
                top:600px;
                right: 0;
                left: -450px;
            }
            .desktop-screen-3{
                top:1190px;
                right: -450px;
                left: 0;
            }
            .desktop-screen-4{
                top:1905px;
                right: 0;
                left: -450px;
            }
        }
        .section-3{
            background: url('../images/sportfest40/section3.jpg')no-repeat 50% 0;
            height: 725px;
            .mokup-iphone{
                width: 300px;
                top: 100px;
                left: 0px;
                z-index: 10;
                video{
                    width: 266px;
                    left: 17px;
                    top: 67px;
                }
            }
            .phone-screen-left1, .phone-screen-left2, .phone-screen-right1, .phone-screen-right2{
                position: absolute; 
                display: block;
            }
            .phone-screen-left1{
                top: 100px;
                left: -500px;
                right: 0;
                margin: auto;     
            }
            .phone-screen-left2{
                top: 170px;
                right: 0;
                left: -950px;
                margin: auto;
            }
            .phone-screen-right1{
                top: 170px; 
                right: -950px;
                left: 0;
                margin: auto;
            }
            .phone-screen-right2{ 
                top: 100px;
                right: -500px;
                left: 0;
                margin: auto;
            }
        }
        .section-4{
            background: url('../images/sportfest40/section4.jpg')no-repeat 50% 0;
            height: 1153px;
            .mokup-ipad{
                width: 949px;
                bottom: 200px;
                left: 0px;
                video{
                    width: 767px;
                    left: 101px;
                    top: 46px;
                }
            }
        }
    }  
}

/*------------------------------------*\
        wawel.html
\*------------------------------------*/
.project-container.wawel{
    section{
        background-color: #f9f5ea;
    }
    @media only screen and (min-width: 64.063em) {
        .section-1{
            background: url('../images/wawel/section1.jpg')no-repeat 50% 0;
            height: 2565px;
        }
        .section-2{
            background: url('../images/wawel/section2.jpg')no-repeat 50% 0;
            height: 712px;
            .mokup-ipad{
                width: 798px;
                left: 0;
                video{
                    width: 587px;
                    left: 122px;
                    top: 61px;
                }
            }
        }
        .section-3{
            background: url('../images/wawel/section3.jpg')no-repeat 50% 0;
            height: 928px;
        }
        .section-4{
            background: url('../images/wawel/section4.jpg')no-repeat 50% 0;
            height: 809px;
            .mokup-iphone{
                width: 312px; 
                top:60px;
                left: 0;
                video{
                    width: 271px;
                    left: 20px;
                    top: 79px;
                }
            }
        }
    }   
}

/*------------------------------------*\
        stefanolompas.html
\*------------------------------------*/
.stefanolompas{
    // section{
    //     background: #434248;
    // }
    @media only screen and (min-width: 64.063em) {
        .section-1{
            background: url('../images/stefanolompas/section1.jpg')no-repeat 50% 0;
            height: 846px;
        }
        .section-2{
            background: url('../images/stefanolompas/section2.jpg')no-repeat 50% 0;
            height: 2176px;
            .mokup-ipad{
                width: 711px;
                bottom: 60px;
                left: 500px;
                video{
                    width: 587px;
                    left: 63px;
                    top: 32px;
                }
            }
        }
        .section-3{
            background: url('../images/stefanolompas/section3.jpg')no-repeat 50% 0;
            height: 2714px;
            .mokup-iphone{
                width: 276px;
                bottom: 80px;
                left: 750px;
                video{
                    width: 240px;
                    left: 18px; 
                    top: 68px;
                }
            }
        }
    }  
}

/*------------------------------------*\
        versal.html
\*------------------------------------*/
.versal{
    // section{
    //     background: #434248;
    // }
    @media only screen and (min-width: 64.063em) {
        .section-1{
            background: url('../images/versal/section1.jpg')no-repeat 50% 0;
            height: 1050px;
        }
        .section-2{
            background: url('../images/versal/section2.jpg')no-repeat 50% 0;
            height: 1945px;
            .mokup-macbook{
                width: 911px;
                bottom: 60px;
                left: 0;
                video{
                    width: 595px;
                    left: 154px;
                    top: 34px;
                }
            }
        }
        .section-3{
            background: url('../images/versal/section3.jpg')no-repeat 50% 0;
            height: 2356px;
        }
    }  
}

/*------------------------------------*\
        istok.html
\*------------------------------------*/
.istok{
    @media only screen and (min-width: 64.063em) {
        .section-1{
            background: url('../images/istok/section1.jpg')no-repeat 50% 0;
            height: 2511px;
        }
        .section-2{
            background: url('../images/istok/section2.jpg')no-repeat 50% 0;
            height: 1297px;
            .mokup-ipad{
                width: 894px;
                bottom: -300px;
                left: 0;
                z-index: 10;
                video{
                    width: 717px;
                    left: 98px;
                    top: 46px;
                }
            }
        }
        .section-3{
            background: url('../images/istok/section3.jpg')no-repeat 50% 0;
            height: 2203px;
        }
    }  
}

/*------------------------------------*\
        gromo-promo.html
\*------------------------------------*/
.gromo-promo{
    @media only screen and (min-width: 64.063em) {
        .section-1{
            background: url('../images/gromo-promo/section1.jpg')no-repeat 50% 0;
            height: 867px;
            .mokup-macbook{
                width: 1044px;
                bottom: 47px;
                left: 0;
                z-index: 10;
                video{
                    width: 682px;
                    left: 177px;
                    top: 40px;
                }
            }
        }
        .section-2{
            background: url('../images/gromo-promo/section2.jpg')no-repeat 50% 0;
            height: 3173px;
            .mokup-iphone{
                width: 300px;
                bottom: 870px;
                left: 725px;
                video{
                    width: 240px;
                    left: 30px;
                    top: 81px;
                }
            }
        }
    }  
}

/*------------------------------------*\
        uaau.html
\*------------------------------------*/
.uaau{
    // section{
    //     background: #464646;
    // }
    @media only screen and (min-width: 64.063em) {
        .section-1{
            background: url('../images/uaau/section1.jpg')no-repeat 50% 0;
            height: 915px;
        }
        .section-2{
            background: url('../images/uaau/section2.jpg')no-repeat 50% 0;
            height: 3990px;
            .mokup-iphone{
                width: 300px;
                bottom: 60px;
                left: -715px;
                video{ 
                    width: 240px;
                    top: 81px;
                    left: 30px;
                }
            }
        }
        .section-3{
            background: url('../images/uaau/section3.jpg')no-repeat 50% 0;
            height: 919px;
            .mokup-ipad{
                width: 734px;
                bottom: 200px;
                left: 0;
                video{ 
                    width: 583px;
                    top: 45px;
                    left: 76px;
                }
            }
        }
    }  
}
 
/*------------------------------------*\
        stanislav-dental.html
\*------------------------------------*/
.dental{
    @media only screen and (min-width: 64.063em) {
        .section-1{
            background: url('../images/dental/section1.jpg')no-repeat 50% 0;
            height: 1473px;
        }
        .section-2{
            background: url('../images/dental/section2.jpg')no-repeat 50% 0;
            height: 1382px;
            .mokup-ipad{
                width: 734px;
                bottom: 60px;
                left: 0;
                video{ 
                    width: 583px;
                    top: 44px;
                    left: 76px;
                }
            }
        }
    }  
} 

/*------------------------------------*\
        versii.html
\*------------------------------------*/
.versii{
    @media only screen and (min-width: 64.063em) {
        .section-1{
            background: url('../images/versii/section1.jpg')no-repeat 50% 0;
            height: 804px;
        }
        .section-2{
            background: url('../images/versii/section2.jpg')no-repeat 50% 0;
            height: 2860px;
            .mokup-macbook{
                width: 911px;
                bottom: 0px;
                left: 0;
                video{ 
                    width: 595px;
                    top: 34px;
                    left: 154px;
                }
            }
        }
        .section-3{
            background: url('../images/versii/section3.jpg')no-repeat 50% 0;
            height: 3283px;
        }
    }  
}

/*------------------------------------*\
        gkt.html
\*------------------------------------*/
.gkt{
    @media only screen and (min-width: 64.063em) {
        .section-1{
            background: url('../images/gkt/section1.jpg')no-repeat 50% 0;
            height: 891px;
        }
        .section-2{
            background: url('../images/gkt/section2.jpg')no-repeat 50% 0;
            height: 3687px;
            .mokup-ipad{
                width: 734px;
                bottom: 50px;
                left: 510px;
                video{ 
                    width: 585px;
                    top: 43px;
                    left: 75px;
                }
            }
        }
        .section-3{
            background: url('../images/gkt/section3.jpg')no-repeat 50% 0;
            height: 2382px;
            .mokup-iphone{
                width: 276px;
                bottom: 80px;
                left: -585px;
                video{ 
                    width: 240px;
                    top: 69px;
                    left: 18px;
                }
            }
        }
    }  
}
 
// ======================== svg style ====================================
.development{
    .st0{fill:none;stroke:#45ca58;stroke-width:1.9987;stroke-miterlimit:10;} .st1{fill:none;stroke:#45ca58;stroke-width:2;stroke-miterlimit:10;}
}
.design{
    .st0{fill:none;stroke:#45ca58;stroke-width:1.9987;stroke-miterlimit:10;} .st1{fill:none;stroke:#45ca58;stroke-width:2;stroke-miterlimit:10;} .st2{fill:none;stroke:#45ca58;stroke-width:1.9518;stroke-miterlimit:10;}
}
.support{
    .st0{fill:none;stroke:#45ca58;stroke-width:1.9987;stroke-miterlimit:10;} .st1{fill:none;stroke:#45ca58;stroke-width:2;stroke-miterlimit:10;} .st2{fill:none;stroke:#45ca58;stroke-width:4;stroke-linecap:round;stroke-miterlimit:10;} .st3{fill:none;stroke:#45ca58;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;}
}


// .slick-active .project h3, .slick-active  .project p, .slick-active .project span, .slick-active .project img.logo, .slick-active .project span {
//     display: block!important;
// }


.send-popup{
    display: none;
    opacity: 0;
    padding: 150px 40px;
    text-align: center;
    @extend .poiretone;
    h3{
        margin-bottom: 25px;
    }
}
.fade{
   animation: display-none-transition 2s;
   -webkit-animation-fill-mode: forwards;
}
@keyframes display-none-transition {
    0% {
        transform: translate(0, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, -50px);
    }
}