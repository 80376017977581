header{
	width:100%;
	position: relative; 
	.row{
		max-width: 1400px;
	}
	.name{
		img{
			margin: 12px 0;
		}
	}
	.top-bar-section{
		[class*="menu-"]{
			margin: auto;
		}
		ul:not(.language-chooser){
			margin:auto;
			li>a{
				@extend .poiretone;
			}
		}
		.language-chooser{
			opacity: 0;
            li a{
                text-transform: uppercase;
                @extend .poiretone;
                padding: 0!important; 
            }
        }
		// p{
		// 	float: right;
		// 	// @include poiretone;
		// 	@extend .poiretone;
		// 	font-size: $font-size-large;  
		// 	line-height: 60px;
		// 	letter-spacing: $letter-spacing-small;
		// 	// opacity: 0;
  //           display: none; 
		// } 
	}
	@media only screen and (max-width: 850px) {
		.name img,
		.top-bar-section p{
			opacity: 1!important;
		}
		.top-bar-section{
			text-align: center; 
			ul {
				li{
					a{
						color: $dark-text;
						padding-left: 0;
					}
				}
			}
			.language-chooser{
				width: auto;
				margin: auto;
				display: inline-block;
				li{
					float: left;
					padding: 0 10px;
				}
				li.active>a:hover{
					background: 0 0;
				}
			}
		}
		.top-bar .toggle-topbar.menu-icon a span::after{
			    box-shadow: 0 0 0 1px #000,0 7px 0 1px #000,0 14px 0 1px #000;
		}
	}
	@media only screen and (min-width: 850px) {
		.top-bar-section{
			display: flex;
			.language-chooser{
				width: 135px;
				li{
					float: right;
				}
	            .active{
	                display: none;
	            }
	        }
		}
	}
}

.fixed{
	transition: 0.3s;
	position: fixed;
	background: #fff!important;
	bottom: auto; 
	.name img,
	.top-bar-section p,
	.language-chooser{
		opacity: 1!important;
	}
	.top-bar-section ul {
		&:hover{
			li a{
				color: #929a9e;
				&:hover{ 
					color:#000;
					transition: 0.3s;
				}
			}
		}
		li,
		li.active:not(.has-form){
			a{
				color: $dark-text;
			}
			&:hover:not(.has-form)>a{ 
				color: $dark-text;  
			}
		}
	}
	.top-bar .toggle-topbar.menu-icon a span::after{
		    box-shadow: 0 0 0 1px #000,0 7px 0 1px #000,0 14px 0 1px #000;
	}
} 

.lining-header-front{ 
	height: 60px;
	display: none;
}
.lining-header{
	height: 60px; 
}


.front-page{
	.name img,
	.top-bar-section p{
		opacity: 0;
	}
}

.project-header{
	background: rgba(0, 0, 0, 0.7)!important;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    p{
    	color:#fff; 
    }
    .top-bar-section ul {
		&:hover{
			li a{
				color: #999;
				// transition: 0.3s;
				&:hover{ 
					color:#fff;
				}
			}
		}
		li{
			a{
				color: #fff;
			}
			&:hover:not(.has-form)>a{ 
				color: #fff;  
			}
		}
	}
	.top-bar .toggle-topbar.menu-icon a span::after{
		    box-shadow: 0 0 0 1px #fff,0 7px 0 1px #fff,0 14px 0 1px #fff;
	}
}