
body {
    font-size: 62.5%;
}
.button, button, a {
    outline: 0;
}
button:focus, a:focus{
    color: inherit;
}

.row{
    max-width: 1100px;
} 

/*------------------------------------*\
     var
\*------------------------------------*/

$green: #45ca58;
$dark: #4b606e;

$footer-ligtnes: #3e4b56;
$footer-darknes: #283743;
$dark-text: #193441;
$red: #ff3800;

$servies-bg: #f5f6f7;


$letter-spacing-large:0.75px;
$letter-spacing-small:0.5px;

$font-size-small: 14px;
$font-size-large: 18px;

/*------------------------------------*\
     mixin
\*------------------------------------*/
// @import url(https://fonts.googleapis.com/css?family=Poiret+One);
// @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,300&subset=latin,cyrillic); 
// @import url(https://fonts.googleapis.com/css?family=Josefin+Sans:400,300);

html, body{
    -webkit-font-smoothing: subpixel-antialiased;
}


html:lang(en-US){
    .poiretone{
        font-family: 'Josefin Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif;
    }
}
html:lang(uk){
    .poiretone{
        font-family:  'Roboto', 'Helvetica', 'Arial', sans-serif;
    }
}

html:lang(en-US){
    .poiretonelight{
        font-family:'Josefin Sans', 'Roboto', 'Helvetica', 'Arial', sans-serif; 
        font-weight: 300;
    }
}
html:lang(uk){
    .poiretonelight{
        font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
        font-weight: 300;
    }
}



// @mixin poiretone {
//     font-family: 'Josefin Sans', 'Poiret One', 'Helvetica', 'Arial', cursive; 
// }
// @mixin poiretonelight {
//     font-family:'Josefin Sans', 'Poiret One', 'Helvetica', 'Arial', cursive; 
//     font-weight: 300;
// }
@mixin opensanslight {
    font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
}
@mixin opensans {
    font-family: 'Open Sans', 'Helvetica', 'Arial', sans-serif;
}

@mixin centered {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

@mixin responsive-margin {
        margin-top: 240px;
    @media screen and (max-width: 1024px){
        margin-top: 140px;
    }
    @media screen and (max-width: 640px){
        margin-top: 70px;
    }
    @media screen and (max-width: 480px){
        margin-top: 15px;
    }
}

