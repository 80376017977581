// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
}
.aligncenter {
  display: block;
  margin: ($column-gutter / 2) auto;
}
.alignleft,
.alignright {
  margin-bottom: ($column-gutter / 2);
}
@media #{$small-up} {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($column-gutter / 2);
  }
  .alignright {
    float: right;
    margin-left: ($column-gutter / 2);
  }
}

// Captions
.wp-caption {
  @extend .thumbnail;
}
.wp-caption-text {
  padding: ($column-gutter / 2);
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}
