$paragraph-margin-bottom: rem-calc(0);

// 34. Top Bar
// - - - - - - - - - - - - - - - - - - - - - - - - -

// $include-html-top-bar-classes: $include-html-classes;

// Background color for the top bar
 $topbar-bg-color: transparent;
// $topbar-bg: $topbar-bg-color;

// Height and margin
$topbar-height: 3.75rem; 
// $topbar-margin-bottom: 0;

// Controlling the styles for the title in the top bar
// $topbar-title-weight: $font-weight-normal;
// $topbar-title-font-size: rem-calc(17);

// Set the link colors and styles for top-level nav
// $topbar-link-color: $white;
// $topbar-link-color-hover: $white;
   // $topbar-link-color-active: $white;
   // $topbar-link-color-active-hover: $white;
// $topbar-link-weight: $font-weight-normal;
 $topbar-link-font-size: 18px;   
// $topbar-link-hover-lightness: -10%; // Darken by 10%
 $topbar-link-bg: transparent;
 $topbar-link-bg-hover: transparent;
// $topbar-link-bg-color-hover: $charcoal;
 $topbar-link-bg-active: transparent;
 $topbar-link-bg-active-hover: transparent;
// $topbar-link-font-family: 'Josefin Sans', 'Poiret One', 'Helvetica', 'Arial', cursive;  
// $topbar-link-text-transform: none;
// $topbar-link-padding: ($topbar-height / 3);
// $topbar-back-link-size: rem-calc(18);
// $topbar-link-dropdown-padding: rem-calc(20);
// $topbar-button-font-size: .75rem;
// $topbar-button-top: 7px;

// Style the top bar dropdown elements
 $topbar-dropdown-bg: transparent;
// $topbar-dropdown-link-color: $white;
// $topbar-dropdown-link-color-hover: $topbar-link-color-hover;
// $topbar-dropdown-link-bg: $oil;
// $topbar-dropdown-link-bg-hover: $jet;
// $topbar-dropdown-link-weight: $font-weight-normal;
// $topbar-dropdown-toggle-size: 5px;
// $topbar-dropdown-toggle-color: $white;
// $topbar-dropdown-toggle-alpha: .4;

// $topbar-dropdown-label-color: $monsoon;
// $topbar-dropdown-label-text-transform: uppercase;
// $topbar-dropdown-label-font-weight: $font-weight-bold;
// $topbar-dropdown-label-font-size: rem-calc(10);
// $topbar-dropdown-label-bg: $oil;

// Top menu icon styles
// $topbar-menu-link-transform: uppercase;
// $topbar-menu-link-font-size: rem-calc(13);
// $topbar-menu-link-weight: $font-weight-bold;
// $topbar-menu-link-color: $white;
// $topbar-menu-icon-color: $white;
// $topbar-menu-link-color-toggled: $jumbo;
// $topbar-menu-icon-color-toggled: $jumbo;
// $topbar-menu-icon-position: $opposite-direction; // Change to $default-float for a left menu icon

// Transitions and breakpoint styles
// $topbar-transition-speed: 300ms;
// Using rem-calc for the below breakpoint causes issues with top bar
$topbar-breakpoint: 850px; // Change to 9999px for always mobile layout
// $topbar-media-query: "#{$screen} and (min-width:#{lower-bound($topbar-breakpoint)})";

// Top-bar input styles
// $topbar-input-height: rem-calc(28);

// Divider Styles
// $topbar-divider-border-bottom: solid 1px scale-color($topbar-bg-color, $lightness: 13%);
// $topbar-divider-border-top: solid 1px scale-color($topbar-bg-color, $lightness: -50%);

// Sticky Class
// $topbar-sticky-class: ".sticky";
// $topbar-arrows: true; //Set false to remove the triangle icon from the menu item
// $topbar-dropdown-arrows: true; //Set false to remove the \00bb >> text from dropdown subnavigation li//